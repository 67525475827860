<script>
import qrcode from 'qrcode'

export default {
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      src: ''
    }
  },
  watch: {
    text: {
      handler: async function() {
        this.src = await qrcode.toDataURL(this.text)
        this.$forceUpdate()
      },
      immediate: true
    }
  },
  render() {
    return <img src={this.src} />
  }
}
</script>

<style lang="less" scoped>
img {
  display: block;
  margin: 0 auto;
}
</style>
